/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  var windowHeight = 0;
  var documentHeight = 0;
  var scrollTimer, UTIL;

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        $(window).on('resize', function () {
          UTIL.fire('common', 'resize');
        });

        $(document).on('scroll', function () {
          UTIL.fire('common', 'scroll');
        });

        UTIL.fire('common', 'resize');
        UTIL.fire('common', 'scroll');

        $('img[data-ratio]:not(.img-responsive):not(.img-overlap)').each(function () {
          $(this).css('height', ($(this).data('ratio') * $(this).parents('div:first').innerWidth()) + 'px');
          $(this).on('load', function () {
            $(this).css('height', 'auto');
          });
        });

        $('.to-top__button').on('click', function (e) {
          e.preventDefault();
          $(this).addClass('to-top__button--active');

          var speed = 900;
          speed = (($(document).scrollTop() + windowHeight) / $(document).height()) * speed;

          $('html, body').animate({
            scrollTop: 0
          }, speed, function () {
            $('.to-top__button').removeClass('to-top__button--active');
          });
        });

        $('img').each(function () {
          $(this).on('load', function () {
            UTIL.fire('common', 'centreVertically', this);
            UTIL.fire('common', 'resize');
          });
        });

        $('.faux-block-link').on('mouseenter', function () {
          $(this).addClass('faux-block-link--hover');
        }).on('mouseleave', function () {
          $(this).removeClass('faux-block-link--hover');
        });
      },
      resize: function () {
        // Update the window height
        windowHeight = $(window).height();
        documentHeight = $(document).height();

        // Store the origin position of the elements so we don't have to load it repeatedly
        $('.transition').each(function () {
          var offset = $(this).offset();
          $(this).data('origin-y', offset.top);
        });

        // Arrows need to be equal width & height
        $('.page__section__arrow, .person__readmore, .work__readmore, .toggle-tertiary, .service__readmore, .icon').each(function () {
          var width = $(this).outerWidth() + 'px';

          $(this).css({
            height: width,
            lineHeight: width
          });
        });

        // Push person readmore links down to bottom
        $('.push-down').each(function () {
          UTIL.fire('common', 'pushDown', this);
        });

        // Vertical centering fix
        $('.vcenter').each(function () {
          UTIL.fire('common', 'centreVertically', this);
        });

        if (documentHeight >= windowHeight) {
          $('.to-top').css('display', 'inline-block');
        }
        else {
          $('.to-top').hide();
        }
      },
      scroll: function () {
        // Activate the transition as the element comes into view
        $('.transition:not(.transition--active)').each(function () {
          var scrollPosition = $(document).scrollTop();

          if ((scrollPosition + windowHeight + 300) > $(this).data('origin-y')) {
            $(this).addClass('transition--active');
          }
        });

        // Handling scroll end functionality
        clearTimeout(scrollTimer);
        scrollTimer = setTimeout(function () {
          UTIL.fire('common', 'scrollEnd');
        }, 250);

        if (!$('body').hasClass('our-work') && !$('body').hasClass('our-services')) {
          if ($(document).scrollTop() > $('.navbar-fixed-top').height()) {
            $('.navbar-fixed-top').addClass('navbar-hidden');
          }
          else {
            $('.navbar-fixed-top').removeClass('navbar-hidden');
          }
        }
      },
      scrollEnd: function () {
        $('.navbar-fixed-top').removeClass('navbar-hidden');
      },
      pushDown: function (el) {
        var $row = $(el).parents('.row:first'),
          rowHeight = $row.find('IMG:first').height(),
          downs = $row.find('.push-down');

        downs.each(function () {
          if ($(this).parent().height() !== rowHeight) {
            $(el).parent().css('height', rowHeight);
          }
        });
      },
      centreVertically: function (el) {
        var $row = $(el).parents('.row:first'),
          $img = $row.find('IMG:visible:first'),
          $feature = $row.find('.page__section__feature'),
          cols = $row.find('.vcenter'),
          rowHeight;

        if ($feature.length === 1) {
          rowHeight = $feature.height();
        }
        else if ($img.length === 1) {
          rowHeight = $img.height();
        }
        else {
          rowHeight = $row.height();
        }

        cols.each(function () {
          if ($(this).height() !== rowHeight) {
            $(this).css({
              height: rowHeight + 'px'
            });
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'home': {
      socialTimer: false,
      init: function () {
        // JavaScript to be fired on the home page
        // $('.slap-social-feed').on('mouseenter', function() {
        //   clearTimeout(Sage.home.socialTimer);
        // }).on('mouseleave', function() {
        //   clearTimeout(Sage.home.socialTimer);
        //   Sage.home.socialTimer = setTimeout(function() {
        //     UTIL.fire('home', 'socialCycle');
        //   }, 5000);
        // }).trigger('mouseleave');

        // Carousel
        $('.carousel').carousel().on('slid.bs.carousel', function () {
          $(this).parents('.row:first').find('.vcenter').each(function () {
            UTIL.fire('common', 'centreVertically', this);
          });
        });
      },
      socialCycle: function () {
        var current = $('.slap-social-feed li:visible').index('.slap-social-feed li');
        var next = ((current + 1) === $('.slap-social-feed li').length) ? 0 : current + 1;

        clearTimeout(Sage.home.socialTimer);
        $('.slap-social-feed li:eq(' + current + ')').fadeOut(function () {
          $('.slap-social-feed li:eq(' + next + ')').fadeIn(function () {
            Sage.home.socialTimer = setTimeout(function () {
              UTIL.fire('home', 'socialCycle');
            }, 5000);
          });
        });
      },
      finalize: function () {

      }
    },
    'about_us': {
      init: function () {
        $('.icon--show-tertiary, .show-tertiary').on('click', function (e) {
          e.preventDefault();
          $('.content--tertiary').slideToggle(function () {
            $('.icon--show-tertiary, .show-tertiary').toggleClass('show-tertiary--expanded');
          });
        });
        // JavaScript to be fired on the about us page
      },
      finalize: function () {

      }
    },
    'our_people': {
      init: function () {
        // Show/hide the person detail
        $('.person__readmore').on('click', function (e) {
          e.preventDefault();
          var $row = $(this).parents('.row'),
            $detail = $row.next();

          $(this).toggleClass('person__readmore--active');
          $detail.slideToggle();
        });
      },
      finalize: function () {

      }
    },
    'careers': {
      init: function () {
        $('.panel-body .btn').on('click', function (e) {
          e.preventDefault();
          $lnk = $(this);
          $('#form-apply > div:not(' + $lnk.attr('href') + ')').hide();
          $('#form-apply > div' + $lnk.attr('href')).show();
          $('html,body').animate({
            scrollTop: $('#form-apply').offset().top
          }, 500);
        });
      },
      finalize: function () {

      }
    },
    'our_services': {
      init: function () {

      },
      finalize: function () {

      }
    },
    'our_work': {
      init: function () {
        UTIL.fire("shared_behaviour", "init", ".work-container");
      }
    },
    'ideas_that_work_from_home': {
      init: function () {
        UTIL.fire("shared_behaviour", "init", ".work-container");
      }
    },
    'shared_behaviour': {
      init: function (container) {
        var workPos = $(container).position().top,
          caseStudiesHeight = $(document).height();
        console.log(workPos);

        $('#case-studies').css('height', caseStudiesHeight);

        $(window).on('hashchange', function () {
          var hash = window.location.hash;

          if (hash !== '') {
            var $caseStudyItem = $('.work > a[href="' + hash + '"]').parent();
            var $caseStudy = $(hash);

            if ($caseStudyItem.length === 1 && $caseStudy.length === 1) {
              var thisPos = $caseStudyItem.position(),
                thisOffset = $caseStudyItem.offset();

              $('#case-studies').css({
                top: thisPos.top,
                left: thisOffset.left,
                right: $(document).width() - thisOffset.left + $caseStudyItem.width(),
                height: $caseStudyItem.height(),
                width: $caseStudyItem.width()
              }).removeClass('case-studies--hidden');

              $('#case-studies').animate({
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: caseStudiesHeight,
                width: $(document).width()
              }, 500, function () {
                $caseStudy.removeClass('hidden');
                $('html, body').animate({
                  scrollTop: workPos
                }, 500);
              });
            }
          }
          else {
            UTIL.fire('shared_behaviour', 'hideCaseStudies');
          }
        }).trigger('hashchange');
      },
      hideCaseStudies: function () {
        UTIL.fire('shared_behaviour', 'stopVideoPlayback');
        $('#case-studies').addClass('case-studies--hidden');
        $('.case-studies__case-study').addClass('hidden');
      },
      stopVideoPlayback: function () {
        var video = $('.case-studies__case-study:visible').find('iframe[src*="vimeo"]');
        if (video.length === 1) {
          var src = video.attr('src');
          video.attr('src', '');
          video.attr('src', src);
        }
      },
      finalize: function () {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.